<template>
    <v-container class="panelgrid" id="scroll-panel">
        <TreeModal :items="tree" :spec="modalDim" v-if="showTree" />
        <v-row v-if="loading" class="justify-center align-center">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </v-row>
        <v-row class="listing">
            <v-row class="accordion">
                <v-data-table :headers="headers"
                              :items="display"
                              :item-class="item_cls"
                              :search="searchText"
                              class="listing-table"
                              :items-per-page="15"
                              dense
                              :loading="loading"
                              loading-text="Carregando..."
                              mobile-breakpoint="750"
                              @click:row="goToDetails">
                    <template v-slot:top>
                        <v-row>
                            <v-col>
                                <span class="title">Entidades</span>
                            </v-col>
                            <v-col cols="5"></v-col>
                            <v-col>
                                <v-btn class="btn-tree"
                                       color="primary"
                                       @click="showPlans"
                                       title="Ver planos"
                                       v-if="isAdm">
                                    <v-icon light>mdi-office-building-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn class="btn-tree"
                                       color="primary"
                                       @click="showUserTree"
                                       title="Exibir árvore">
                                    <v-img class="btn-add-img"
                                           src="@/assets/icons/person_icon.svg"/>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn class="btn-add" color="primary"
                                       @click="goToNewEntity"
                                       title="Adicionar nova entidade">
                                    <v-img class="btn-add-img"
                                           src="@/assets/icons/add_circle_icon.svg"/>
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field placeholder="Pesquisar"
                                              outlined rounded dense
                                              prepend-inner-icon="mdi-magnify"
                                              v-model="searchUser" />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <v-img class="btn-icon"
                               src="@/assets/icons/edit_icon.svg" />
                        <span>{{ item.name }}</span>
                    </template>
                    <template v-slot:[`item.email`]="{ item }">
                        <span>{{ item.email }}</span>
                    </template>
                    <template v-slot:[`item.planName`]="{ item }">
                        <span>{{ item.planName }}</span>
                    </template>
                    <template v-slot:[`item.isActive`]="{ item }">
                        <v-icon title="Ativo"
                                class="active"
                                v-if="item.isActive">mdi-account</v-icon>
                        <v-icon title="Somente Importer"
                                class="inactive"
                                v-else>mdi-account-network-off</v-icon>
                    </template>
                </v-data-table>
            </v-row>
            <v-row v-if="!(items && items.length)">
                <v-col v-if="!loading">Sem entidades cadastradas.</v-col>
                <v-col v-if="loading">Carregando...</v-col>
            </v-row>
            <v-row v-if="!(display && display.length)">
                <v-col>Não encontrado.</v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.listing-table .row .v-btn {
    border-radius: 10px;
    background-color: #1296E3;
    color: #D7D7D7;
    min-width: unset;
    min-height: unset;
}
.listing-table .row .btn-add-img {
    max-width: 1rem !important;
    max-height: 1rem !important;
}
.listing-table .row .v-text-field {
    width: 200px !important;
}
.row.listing {
    margin: 0;
}
.listing > .row {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}
.listing .row .col {
    font-size: 0.75em;
    margin: 0.25em;
}
.listing-table {
    min-width: 100%;
    width: 100%;
}
.listing-table table tbody tr td .btn-icon {
    max-width: 1rem !important;
    max-height: 1rem !important;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
}
@media screen and (max-width: 750px) {
}
</style>

<style>
.listing-table .row .title {
    text-align: left !important;
    font-weight: bold !important;
    padding: 0.5rem;
    font-size: 1.25rem;
}
.listing-table table tbody tr td .active {
    color: green;
}
.listing-table table tbody tr td .inactive {
    color: red;
}
.listing-table table tbody tr.disabled {
    background-color: rgba(34,102,136,0.35);
}
</style>

<script lang="js">
import client       from '@/services/AdminService';
import idFormatter  from '@/services/IdValueFormatter';
import debouncer    from '@/utils/debouncer';
import EventBus     from "@/views/bus/bus";
import TreeModal    from './components/TreeModal.vue';

export default {
    data            : () => ({
        loading     : false,
        pageNum     : undefined,
        pageLen     : 50,
        finished    : false,
        headers     : [
            { text: "Nome", align: "start", true: "name", value: "name", width: '30%' },
            { text: "Email", value: "email", sortable: false, width: '30%' },
            { text: "Plano", value: "planName", sortable: false, width: '30%' },
            { text: "Ativo", value: "isActive", sortable: false, width: '10%' }
        ],
        items       : [],
        tree        : [],
        display     : [],
        modalDim    : { width: 0.8, height: 0.8 },
        searchText  : '',
        selected    : undefined,
        showTree    : false,
        searchUser  : undefined,
        isAdm       : false,
        client,
        idFormatter,
        debouncer,
        expander    : undefined
    }),
    components      : {
        TreeModal
    },
    methods         : {
        async loadItems () {
            if (this.finished) { return; }
            this.loading        = true;
            this.items.length   = 0;
            this.display.length = 0;
            try {
                const page      = this.pageNum === undefined ? 0 : this.pageNum + 1;
                const resp      = await this.client.getAdminEntities(page, this.pageLen, true);
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.pageNum    = page;
                this.items.push(...resp.content);
                this.display.push(...resp.content);
                this.tree.push(...resp.content);
                this.finished   = resp.content.length < this.pageLen;
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        formatDate      (v) {
            if (!v) { return '' }
            let xp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{2,3})$/;
            let mt = `${v}`.match(xp);
            return mt ? `${mt[3]}/${mt[2]}/${mt[1]} ${mt[4]}:${mt[5]}:${mt[6]}` : v;
        },
        formatSysRole   (item) {
            return item && item.systemRole && item.systemRole.description || 'Não definida';
        },
        formatRole      (item) {
            return item.role && item.role.fullName || 'Não definido';
        },
        hasPermissions  (item) {
            return item.role && item.role.permissions && item.role.permissions.length;
        },
        formatPerms     (item) {
            const data = item.role.permissions
                .map((p) => p.permission && p.permission.fullName)
                .filter((s) => !!s)
                .join(', ');
            return data || 'Não definidas';
        },
        goToNewEntity   () {
            this.$router.push('/entity-new');
        },
        goToDetails     (item) {
            if (!item) { return; }
            this.$router.push({
                name        : 'entity-edit',
                params      : {
                    id      : `${item.entityId}` || 'empty',
                    item,
                    entities: this.items
                }
            });
        },
        foundIn         (l, r) {
            return !!l && l.toLowerCase().indexOf(r.toLowerCase()) >= 0;
        },
        isSearchMatch   (item, search) {
            return  !!item &&
                    !!search &&
                    search.length >= 3 &&
                    (   this.foundIn(item.name, search) ||
                        this.foundIn(item.email, search));
        },
        showPlans       () {
            this.$router.push("/entity-plans");
        },
        showUserTree    () {
            this.showTree = true;
        },
        closeUserTree   () {
            this.showTree = false;
        },
        item_cls        (item) {
            return (item && item.isActive) ? '' : 'disabled';
        }
    },
    computed        : {
    },
    watch           : {
        searchUser      (nv) {
            this.expander(nv);
        }
    },
    created         () {
        EventBus.$on("closeTreeModal", this.closeUserTree);
    },
    mounted         () {
        this.expander   = this.debouncer((search) => {
            this.display.length = 0;
            const input         = search && search.trim() || '';
            if (!input) {
                this.display.push(...this.items);
                return;
            }
            this.display.push(...this.items.filter((c) => this.isSearchMatch(c, search)));
        }, 300);
        const sysId = this &&
                      this.$store &&
                      this.$store.getters &&
                      this.$store.getters.$user &&
                      this.$store.getters.$user.systemRoleId ||
                      0;
        this.isAdm = sysId === 1;
        this.loadItems();
    }
}
</script>