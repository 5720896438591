const Layout          = () => import("../../external/Layout.vue");
const Auth            = () => import("./Auth.vue");

const AuthRoutes      = {
    component         : Layout,
    children          : [
        {
            path      : "",
            name      : "Login",
            component : Auth,
            meta      : { requiresAuth: false },
        },
        { path: "*", redirect: "/auth" },
    ],
};

export default AuthRoutes;