<template>
    <div class="home">
        <div class="content">
            <v-menu :close-on-click="true" :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon class="blue" v-bind="attrs" v-on="on" title="Menu">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in user_items" :key="index" :value="index"
                                 @click="menuClick(item)">
                        <v-list-item-title>
                            <v-icon light>{{ item.icon }}</v-icon>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="menuClick('logout')">
                        <v-list-item-title class="embed-icon">
                            <v-img src="@/assets/icons/sair_icon.svg" class="img" />
                            Sair
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <component :is="view" />
        </div>
    </div>
</template>

<style scoped>
.home {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}
.home .content {
    margin: 0;
    padding: 1em;
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.embed-icon .img {
    width: 20px;
    height: 20px;
    display: inline-block;
}
@media screen and (max-width: 750px) {
}
</style>

<script lang="js">
import  CommercialPlans from    '../commercialPlan/CommercialPlans.vue';
import  Permissions     from    '../permission/Permissions.vue';
import  Roles           from    '../role/Roles.vue';
import  Entities        from    '../entity/Entities.vue';
import  client          from    '../../../services/AdminService';

export default {
    data            : () => ({
        title       : 'Menu',
        view        : 'Entities',
        client,
        items       : [
            {
                id   : 1,
                value: 'Entities',
                title: 'Entidades',
                icon : 'mdi-account'
            },
            {
                id   : 2,
                value: 'Permissions',
                title: 'Permissões',
                icon : 'mdi-account-check-outline',
                isAdm: true
            },
            {
                id   : 3,
                value: 'Roles',
                title: 'Grupos de Permissões',
                icon : 'mdi-account-details',
                isAdm: true
            },
            {
                id   : 4,
                value: 'CommercialPlans',
                title: 'Planos empresariais',
                icon : 'mdi-office-building-outline',
                isAdm: true
            }
        ],
        user_items  : []
    }),
    components      : {
        CommercialPlans,
        Permissions,
        Roles,
        Entities
    },
    methods         : {
        menuClick (item) {
            if (typeof item === 'string') {
                switch (item) {
                    case 'logout':
                        this.client.doLogout(this.$router);
                        return;
                }
            }
            item && localStorage.setItem('home/view', this.view = item.value);
        }
    },
    watch           : {
        view        : function (nv, ov) {
            const role = this &&
                         this.$store &&
                         this.$store.getters &&
                         this.$store.getters.$user &&
                         this.$store.getters.$user.systemRoleId ||
                         0;
            if (role !== 1 && ((nv !== 'Entities') || (this.view !== 'Entities'))) {
                this.view = 'Entities';
            }
        }
    },
    mounted         () {
        const role  = this.$store.getters.$user.systemRoleId;
        const isAdm = role === 1;
        this.user_items.push(...this.items.filter((i) => isAdm || !i.isAdm));
        const view  = localStorage.getItem('home/view') || 'Entities';
        this.view   = view;
    }
}
</script>