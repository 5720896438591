import Vue from "vue";

const LOAD_DEFAULTS     = {
    showLoading         : true,
    showException       : true
};
//const SRV_ADDR          = 'http://localhost:8187/';
const SRV_ADDR          = 'https://viacep.com.br/ws/'; //'http:localhost:8187';

export default {
    getHeaders              () {
        let result  = {
            Accept                          : "application/json",
        };
        return result;
    },
    callRest                (path, options) {
        const { showException, showLoading } = options || {
            showException: true,
            showLoading  : true
        };
        const urlHead       = SRV_ADDR;
        const urlTail       = '/json/';
        const headers       = this.getHeaders();
        const event         = {
            showLoading,
            requestId       : new Date().getTime(),
            payload         : undefined
        };

        if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestStart", event);
        }

        return Vue.http({ method: 'GET', undefined, headers, url: `${urlHead}${path}${urlTail}` })
            .then((resp) => {
                if (Vue.$globalEvent) {
                    Vue.$globalEvent.$emit("httpRequestEnd", resp.body || resp);
                }
                return resp.body || resp
            })
            .catch((reason) => {
                Object.assign(reason, { method: 'GET', showException });
                throw reason;
            });
    },
    searchZip               (zipCode, options = LOAD_DEFAULTS) {
        return this.callRest(zipCode, options)
            .then((resp) => ({
                success: true,
                content: resp
            }))
            .catch((reason) => ({
                success: false,
                message: reason.body.message || reason.body.title || reason.message || 'Erro não identificado',
                reason
            }));
    },
    searchAddress           (state, city, address, options = LOAD_DEFAULTS) {
        const ecity         = encodeURI(city);
        const eaddress      = encodeURI(address);
        return this.callRest(`${state}/${ecity}/${eaddress}`, options)
            .then((resp) => ({
                success: true,
                content: resp
            }))
            .catch((reason) => ({
                success: false,
                message: reason.body.message || reason.body.title || reason.message || 'Erro não identificado',
                reason
            }));
    }
}