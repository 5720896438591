import Vue          from    "vue";
import VueResource  from    "vue-resource";
import App          from    "./App.vue";
import router       from    "./router";
import store        from    "./store";
import vuetify      from    "./plugins/vuetify";
import VueTheMask   from    "vue-the-mask";
import Toast        from    "vue-toastification";
import                      "vue-toastification/dist/index.css";
import                      "@/style/global/global.css";
import VueHtml2pdf  from    "vue-html2pdf";

Vue.use(VueTheMask);

const   options             = {
    transition              : "Vue-Toastification__bounce",
    maxToasts               : 20,
    newestOnTop             : true,
    position                : "top-right",
    timeout                 : 5000,
    closeOnClick            : true,
    pauseOnHover            : true,
    draggable               : true,
    draggablePercent        : 0.6,
    showCloseButtonOnHover  : true,
    hideProgressBar         : false,
    icon                    : true,
};

Vue.use(Toast, options);

Vue.config.productionTip    = false;
Vue.use(VueResource);
Vue.use(VueHtml2pdf);
Vue.$globalEvent            = new Vue();

new Vue({
    router,
    store,
    vuetify,
    render                  : (h) => h(App),
}).$mount("#app");
