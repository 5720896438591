<template>
    <v-container class="panelgrid">
        <v-row v-if="loading" class="justify-center align-center">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </v-row>
        <v-row class="listing">
            <v-row class="accordion">
                <v-data-table :headers="headers"
                              :items="items"
                              class="listing-table"
                              :items-per-page="15"
                              dense
                              :loading="loading"
                              :expanded.sync="expanded"
                              item-key="fullName"
                              show-expand
                              loading-text="Carregando..."
                              mobile-breakpoint="750">
                    <template v-slot:top>
                        <v-row>
                            <v-col>
                                <span class="title">Grupos de permissões</span>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.fullName="slotData">
                        <div @click="toggleExpanded(slotData)">{{ slotData.item.fullName }}</div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" v-if="!(item.permissions && item.permissions.length)">
                            <div>Sem permissões associadas.</div>
                        </td>
                        <td :colspan="headers.length" v-if="item.permissions && item.permissions.length">
                            <div v-for="pair in item.permissions" :key="pair.permissionId">
                                {{ pair.permission.fullName }}
                            </div>
                        </td>
                    </template>
                    <template v-slot:item.createdDate="{ item }">
                        <span>{{ formatDate(item.createdDate) }}</span>
                    </template>
                    <template v-slot:item.cancelledDate="{ item }">
                        <span>{{ formatDate(item.cancelledDate) }}</span>
                    </template>
                </v-data-table>
            </v-row>
            <v-row v-if="!(items && items.length)">
                <v-col cols="11" v-if="!loading">Sem papéis cadastrados.</v-col>
                <v-col cols="11" v-if="loading">Carregando...</v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.listing-table .row .v-btn {
    border-radius: 10px;
    background-color: #1296E3;
    color: white;
    min-width: unset;
    min-height: unset;
}
.listing-table .row .btn-add-img {
    max-width: 1rem !important;
    max-height: 1rem !important;
}
.listing-table .row .v-text-field {
    width: 200px !important;
}
.row.listing {
    margin: 0;
}
.listing > .row {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}
.listing .row .col {
    font-size: 0.75em;
    margin: 0.25em;
}
.listing-table {
    min-width: 100%;
    width: 100%;
}
.listing-table table tbody tr td .btn-icon {
    max-width: 1rem !important;
    max-height: 1rem !important;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
}
@media screen and (max-width: 750px) {
}
</style>

<style>
.listing-table .row .title {
    text-align: left !important;
    font-weight: bold !important;
    padding: 0.5rem;
    font-size: 1.25rem;
}
</style>

<script lang="js">
import client       from '../../../services/AdminService';

export default {
    data                : () => ({
        loading         : false,
        items           : [],
        expanded        : [],
        headers         : [
            { text: "Nome"        , value: "fullName"     , width: "30%", sortable: false },
            { text: "Descrição"   , value: "description"  , width: "30%", sortable: false },
            { text: "Criação"     , value: "createdDate"  , width: "20%", sortable: false },
            { text: "Cancelamento", value: "cancelledDate", width: "20%", sortable: false },
        ],
        selected        : undefined,
        client
    }),
    methods             : {
        async loadItems () {
            this.loading    = true;
            this.items      = [];
            try {
                const resp = await this.client.getAdminRoles();
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.items = resp.content;
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        formatDate      (v) {
            if (!v) { return '' }
            let xp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{2,3})$/;
            let mt = `${v}`.match(xp);
            return mt ? `${mt[3]}/${mt[2]}/${mt[1]} ${mt[4]}:${mt[5]}` : v;
        },
        toggleExpanded  (d) {
            const row   = d.index;
            const exp   = this.expanded.findIndex((e) => e === d.item);
            if (exp >= 0) {
                this.expanded.splice(exp, 1);
            } else {
                this.expanded.push(d.item);
            }
        }
    },
    mounted             () {
        const role      = this.$store.getters.$user.systemRoleId;
        if (role !== 1) {
            this.$router.push('/home');
            return;
        }
        this.loadItems();
    }
}
</script>