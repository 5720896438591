export default {
    format      (v) {
        const parsed = new Date(this.prepare(v));
        return parsed.toLocaleDateString();
    },
    prepare     (v) {
        switch (v.length) {

            case 10: return `${v}T00:00:00`;
            case 13: return `${v}:00:00`;
            case 16: return `${v}:00`;
            default: return v;
        }
    },
    validate    (v) {
        if (!v) { return true; }
        const parsed = new Date(v);
        return parsed.toISOString().startsWith(v);
    }
}