import  Layout              from    '@/views/internal/Layout.vue';
import  EntityPlans         from    './EntityPlans';
import  CommercialPlanNew   from    './CommercialPlanNew.vue';

const   PlanRoutes          = {
    component               : Layout,
    children                : [
        {
            name            : 'entity-plans',
            path            : '/entity-plans',
            component       : EntityPlans,
            meta            : {}
        },
        {
            name            : 'commercial-plan-new',
            path            : '/commercial-plan-new',
            component       : CommercialPlanNew,
            meta            : {}
        },
        {
            name            : 'commercial-plan-edit',
            path            : '/commercial-plan-edit/:id',
            component       : CommercialPlanNew,
            props           : true,
            meta            : {}
        }
    ]
};

export default          PlanRoutes;
