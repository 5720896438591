<template>
    <v-container class="panelgrid">
        <EditEntityPlanModal :instance="selected" :spec="modalDim" v-if="showEditor" />
        <v-row v-if="loading" class="justify-center align-center">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </v-row>
        <v-row class="ctrl-bar">
            <v-col>
                <v-btn @click="goBack" color="primary">Voltar</v-btn>
            </v-col>
        </v-row>
        <v-row class="listing">
            <v-row class="accordion">
                <v-data-table :headers="headers"
                              :items="display"
                              :item-class="item_cls"
                              :search="searchUser"
                              class="listing-table"
                              :items-per-page="15"
                              dense
                              :loading="loading"
                              loading-text="Carregando..."
                              mobile-breakpoint="750"
                              @click:row="editEntityPlan">
                    <template v-slot:top>
                        <v-row>
                            <v-col>
                                <span class="title">Planos de entidades</span>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field placeholder="Pesquisar"
                                              outlined rounded dense
                                              prepend-inner-icon="mdi-magnify"
                                              append-icon="mdi-close"
                                              class="search-edit"
                                              @click:append="clearSearch"
                                              v-model="searchUser" />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.entityName="{ item }">
                        <span>{{ item.entityName }}</span>
                    </template>
                    <template v-slot:item.entityEmail="{ item }">
                        <span>{{ item.entityEmail }}</span>
                    </template>
                    <template v-slot:item.planName="{ item }">
                        <span>{{ item.planName }}</span>
                    </template>
                    <template v-slot:item.startDate="{ item }">
                        <span>{{ formatDate(item.startDate) }}</span>
                    </template>
                </v-data-table>
            </v-row>
            <v-row v-if="!(items && items.length)">
                <v-col cols="12" v-if="!loading">Sem planos cadastrados.</v-col>
                <v-col cols="12" v-if="loading">Carregando...</v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.panelgrid .ctrl-bar {
    margin-top: 1em;
    margin-bottom: 1em;
}
.listing-table .row .v-btn {
    border-radius: 10px;
    background-color: #1296E3;
    color: white;
    min-width: unset;
    min-height: unset;
}
.listing-table .row .btn-add-img {
    max-width: 1rem !important;
    max-height: 1rem !important;
}
.listing-table .row .v-text-field {
    width: 300px !important;
}
.row.listing {
    margin: 0;
}
.listing > .row {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}
.listing .row .col {
    font-size: 0.75em;
    margin: 0.25em;
}
.listing-table {
    min-width: 100%;
    width: 100%;
}
.listing-table table tbody tr td .btn-icon {
    max-width: 1rem !important;
    max-height: 1rem !important;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
}
@media screen and (max-width: 750px) {
}
</style>

<style>
.listing-table .row .title {
    text-align: left !important;
    font-weight: bold !important;
    padding: 0.5rem;
    font-size: 1.25rem;
}
</style>

<script lang="js">
import EventBus             from '@/views/bus/bus';
import client               from '@/services/AdminService';
import debouncer            from '@/utils/debouncer';
import EditEntityPlanModal  from './components/EditEntityPlanModal.vue';

export default {
    data            : () => ({
        loading     : false,
        headers     : [
            { text: "Entidade", value: "entityName" , width: "25%", sortable: false },
            { text: "Email"   , value: "entityEmail", width: "25%", sortable: false },
            { text: "Plano"   , value: "planName"   , width: "25%", sortable: false },
            { text: "Início"  , value: "startDate"  , width: "25%", sortable: false }
        ],
        items       : [],
        display     : [],
        modalDim    : { width: 0.8, height: 0.8 },
        showEditor  : false,
        searchUser  : '',
        selected    : undefined,
        expander    : undefined,
        client,
        debouncer
    }),
    components      : {
        EditEntityPlanModal
    },
    methods         : {
        preformat       (plans)  {
            const size = plans && plans.length || 0;
            if (!size) { return; }
            for (let i = 0; i < size; ++i) {
                const plan = plans[i];
                if (plan.startDate   === '1900-01-01T00:00:00') { plan.startDate   = undefined; }
                if (plan.stopDate    === '1900-01-01T00:00:00') { plan.stopDate    = undefined; }
                if (plan.createdDate === '1753-01-01T00:00:00') { plan.createdDate = undefined; }
                if (plan.expiredDate === '1753-01-01T00:00:00') { plan.expiredDate = undefined; }
            }
        },
        async loadItems ()  {
            if (this.loading) { return; }
            this.loading        = true;
            this.items.length   = 0;
            this.display.length = 0;
            try {
                var resp = await this.client.getEntityPlans();
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.preformat(resp.content);
                this.items.push(...resp.content);
                this.display.push(...resp.content);
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        formatDate      (v) {
            if (!v) { return '' }
            if (v.startsWith('0001-01-01T00:00:00')) { return '...'; }
            let   xp  = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(.(\d{1,3}))?$/;
            let   mt  = `${v}`.match(xp);
            return mt ? `${mt[3]}/${mt[2]}/${mt[1]} ${mt[4]}:${mt[5]}` : v;
        },
        foundIn         (l, r) {
            return !!l && l.toLowerCase().indexOf(r.toLowerCase()) >= 0;
        },
        isSearchMatch   (item, search) {
            return  !!item &&
                    !!search &&
                    search.length >= 3 &&
                    (   this.foundIn(item.entityName, search) ||
                        this.foundIn(item.entityEmail, search));
        },
        item_cls        (item) {
            return (item && item.planName) ? '' : 'disabled';
        },
        openEditor      () {
            this.showEditor = true;
        },
        closeEditor     () {
            this.showEditor = false;
            return this.loadItems();
        },
        editEntityPlan  (item) {
            if (!item) { return; }
            this.selected = item;
            this.openEditor();
        },
        goBack          ()  {
            this.$router.back();
        },
        clearSearch     ()  {
            this.searchUser = '';
        }
    },
    watch           : {
        searchUser      (nv) {
            this.expander(nv);
        }
    },
    created         () {
        EventBus.$on("closeEntityPlanEditor", this.closeEditor);
    },
    mounted         () {
        const sysId = this &&
                      this.$store &&
                      this.$store.getters &&
                      this.$store.getters.$user &&
                      this.$store.getters.$user.systemRoleId ||
                      0;
        if (sysId !== 1) {
            this.goBack();
        }
        this.expander   = this.debouncer((search) => {
            this.display.length = 0;
            const input         = search && search.trim() || '';
            if (!input) {
                this.display.push(...this.items);
                return;
            }
            this.display.push(...this.items.filter((c) => this.isSearchMatch(c, search)));
        }, 300);
        return this.loadItems();
    }
}
</script>