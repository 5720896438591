import  Vue     from    "vue";
import  Vuex    from    "vuex";

Vue.use(Vuex);

function prepare (value) {
    return typeof value === 'string' ?
        value :
        value === null || value === undefined ?
        'null' :
        JSON.stringify(value);
}

export default new Vuex.Store({
    state                           : {
        token                       : undefined,
        user                        : undefined,
        expires                     : undefined
    },
    mutations                       : {
        SET_CURRENT_TOKEN           (state, payload)    {
            sessionStorage.removeItem("token");
            const content           = prepare(payload);
            sessionStorage.setItem("token", content);
            state.token             = content;
        },
        SET_EXPIRES_TOKEN           (state, payload)    {
            const content           = prepare(payload);
            sessionStorage.removeItem("expires");
            sessionStorage.setItem("expires", content);
            state.expires           = content;
        },
        SET_CURRENT_USER            (state, payload)    {
            const content           = prepare(payload);
            sessionStorage.removeItem("user");
            sessionStorage.setItem("user", content);
            state.user              = content;
        },
    },
    actions                         : {
    },
    getters                         : {
        $token                      (state)             {
            return state.token || sessionStorage.getItem('token');
        },
        $expires                    (state)             {
            return state.expires || sessionStorage.getItem('expires');
        },
        $user                       (state)             {
            return JSON.parse(state.user || sessionStorage.getItem('user'));
        },
    },
});
