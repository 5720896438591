<template>
    <v-form ref="newcplan" v-model="valid" lazy-validation :disabled="loading">
        <v-container class="panelgrid">
            <v-row v-if="loading" class="justify-center align-center">
                <v-progress-circular :size="35"
                                    color="primary"
                                    indeterminate />
            </v-row>
            <v-row class="title">
                <v-col>
                    <span>{{ isUpdate ? "Editar Plano" : "Novo Plano" }}</span>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col>
                    <v-text-field v-model="instance.fullname"
                                  :rules="validations.fullname"
                                  placeholder="Nome"
                                  label="Nome"
                                  outlined
                                  dense
                                  required />
                </v-col>
                <v-col>
                    <v-text-field v-model="instance.description"
                                  placeholder="Descrição"
                                  label="Descrição"
                                  outlined
                                  dense
                                  required />
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col>
                    <v-text-field v-model="iMaxLogins"
                                  placeholder="Logins"
                                  label="Logins"
                                  type="number"
                                  outlined
                                  dense />
                </v-col>
                <v-col>
                    <v-text-field v-model="iMaxWallets"
                                  placeholder="Carteiras"
                                  label="Carteiras"
                                  type="number"
                                  outlined
                                  dense />
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col>
                    <v-text-field v-model="iPlanPeriod"
                                  placeholder="Período"
                                  label="Período"
                                  type="number"
                                  outlined
                                  dense />
                </v-col>
                <v-col>
                    <v-text-field v-model="instance.expiredDate"
                                  placeholder="Expiração"
                                  label="Expiração"
                                  append-icon="mdi-close"
                                  @click:append="clearExpiration"
                                  type="datetime-local"
                                  outlined
                                  dense />
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col class="text-center">
                    <v-btn @click="savePlan"
                           title="Salvar os dados do plano"
                           class="btn-ok"
                           :disabled="noChanges()">Salvar</v-btn>
                </v-col>
                <v-col class="text-center">
                    <v-btn @click="clearPlan"
                           title="Limpar dados preenchidos"
                           class="btn-clean">Limpar</v-btn>
                </v-col>
                <v-col class="text-center">
                    <v-btn @click="goBack"
                           title="Cancelar e voltar para a listagem"
                           class="btn-cancel">Voltar</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.fields .col label span.label {
    margin-right: 1em;
}
.v-text-field input {
    padding-left: 4px;
}
.fields .col button {
    font-weight: bold;
    border-radius: 26px;
}
.fields .col .btn-ok {
    background-color: #33CC33;
    color: white;
}
.fields .col .btn-clean {
    background-color: #33CCCC;
    color: white;
}
.fields .col .btn-cancel {
    background-color: #CC3333;
    color: white;
}
.title .col .btn-status {
    font-weight: bold;
    border-radius: 26px;
    color: white;
}
.fields .col.label {
    align-content: center;
}
.fields .col .label {
    border: 1px solid #CCCCCC;
    border-radius: 27px;
    padding: 1em;
}
.title .hl {
    color: #882266;
}
</style>

<style>
</style>

<script lang="js">
import  dateFormatter   from    '@/services/DateFormatter';
import  client          from    '@/services/AdminService';
import  keyFilter       from    '@/utils/filter';

export default {
    data                : () => ({
        loading         : false,
        valid           : true,
        isUpdate        : false,
        isAdmin         : false,
        isSelf          : false,
        source          : undefined,
        instance        : {
            commercialPlanId        : 0,
            fullname                : '',
            description             : '',
            planPeriod              : 0,
            maxNumberLogins         : 0,
            maxNumberWallets        : 0,
            maxNumberAccountAccess  : 0,
            createdByEntityId       : 0,
            createdDate             : '',
            expiredDate             : ''
        },
        plans                       : [],
        validations                 : {
            fullname                : [(v) => !!v || 'Nome é obrigatório.'],
        },
        dateFormatter,
        client,
        keyFilter
    }),
    methods             : {
        async loadData  () {
            if (this.loading) { return; }
            this.plans.length       = 0;
            const hasParams         = !!this.$route.params.item &&
                                      !!this.$route.params.entities;
            if (hasParams) {
                const               {
                    item,
                    plans
                }                   = this.$route.params;
                this.source         = item;
                this.plans.push(...plans);
                const user          = this.$store.getters.$user;
                this.isSelf         = !user || item.userId === user.id;
                Object.getOwnPropertyNames(item).forEach((prop) =>
                    this.instance[prop] = item[prop]);
            } else {
                const parts         = window.location.pathname.split('/');
                const id            = this.isUpdate ? +(parts[parts.length - 1]) : 0;
                this.loading        = true;
                try {
                    const resp      = await this.client.getAdminCommercialPlans();
                    if (!resp.success) {
                        this.$toast.error(resp.message || 'Falha ao carregar planos!');
                        return;
                    }
                    const plans     = resp.content;
                    this.plans.push(...plans);
                    if (this.isUpdate) {
                        const target = plans.filter((p) => p.commercialPlanId === id)[0];
                        if (!target) {
                            this.$toast.error(`Entidade com id ${id} não encontrada`);
                            setTimeout(() => this.goBack(), 2000);
                            return;
                        }
                        this.source  = target;
                        Object.getOwnPropertyNames(target).forEach((prop) => this.instance[prop] = target[prop]);
                    }
                } catch (e) {
                    this.$toast.error(e.message || 'Erro não identificado');
                } finally {
                    this.loading = false;
                }
            }
        },
        goBack          () {
            this.$router.back();
        },
        clearPlan       () {
            const p                     = this.instance;
            const u                     = undefined;
            p.fullname                  = u;
            p.description               = u;
            p.maxNumberLogins           = 0;
            p.maxNumberWallets          = 0;
            p.maxNumberAccountAccess    = 0;
            p.planPeriod                = 0;
            p.expiredDate               = u;
        },
        prepare         () {
            if (!this.instance.createdDate) { this.instance.createdDate = undefined; }
            if (!this.instance.expiredDate) { this.instance.expiredDate = undefined; }
            if (!this.instance.fullname)    { this.instance.fullname    = undefined; }
        },
        savePlan        () {
            if (this.loading) { return; }
            const isValid = this.$refs.newcplan.validate();
            if (!isValid) {
                return;
            }
            this.prepare();
            this.loading = true;
            return this.client.saveAdminCommercialPlan(this.instance)
                .then((resp) => {
                    this.loading = false;
                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }

                    this.$toast.info('Plano armazenado com sucesso.');
                    this.clearPlan();
                    this.goBack();
                });
        },
        clearExpiration () {
            this.instance.expiredDate = undefined;
        },
        noChanges       () {
            if (!this.instance.commercialPlanId) { return false; }
            if (!this.source)                    { return false; }
            return (this.instance.description || '')    === (this.source.description || '')    &&
                   (this.instance.expiredDate || '')    === (this.source.expiredDate || '')    &&
                   (this.instance.fullname    || '')    === (this.source.fullname    || '')    &&
                   this.instance.maxNumberAccountAccess === this.source.maxNumberAccountAccess &&
                   this.instance.maxNumberLogins        === this.source.maxNumberLogins        &&
                   this.instance.maxNumberWallets       === this.source.maxNumberWallets       &&
                   this.instance.planPeriod             === this.source.planPeriod;
        }
    },
    computed            : {
        iMaxAccess      : {
            get         : function () {
                return this.instance.maxNumberAccountAccess && `${this.instance.maxNumberAccountAccess}` || '';
            },
            set         : function (v) {
                this.instance.maxNumberAccountAccess = v && (+v) || 0;
            }
        },
        iMaxLogins      : {
            get         : function () {
                return this.instance.maxNumberLogins && `${this.instance.maxNumberLogins}` || '';
            },
            set         : function (v) {
                this.instance.maxNumberLogins = v && (+v) || 0;
            }
        },
        iMaxWallets     : {
            get         : function () {
                return this.instance.maxNumberWallets && `${this.instance.maxNumberWallets}` || '';
            },
            set         : function (v) {
                this.instance.maxNumberWallets = v && (+v) || 0;
            }
        },
        iPlanPeriod     : {
            get         : function () {
                return this.instance.planPeriod && `${this.instance.planPeriod}` || '';
            },
            set         : function (v) {
                this.instance.planPeriod = v && (+v) || 0;
            }
        }
    },
    mounted             () {
        const user              = this.$store.getters.$user;
        this.isUpdate           = window.location.pathname.indexOf('commercial-plan-edit') >= 0;
        this.isAdmin            = user && user.systemRoleId === 1;
        if (!user) {
            this.goBack();
            return;
        }
        this.validations.fullname.push((v) => !this.plans.some((p) =>
                (p.commercialPlanId !== this.instance.commercialPlanId) &&
                (p.fullname === v)) || 'Nome em uso');
        return this.loadData();
    }
}
</script>