export default {
    format      (fmt, value) {
        const input = value && value.trim() || '';
        const size  = input.length;
        if (size === 0) { return ''; }
        switch (fmt) {
            case 0:
                let cpf = [input.substring(0, 3)];
                if (size <= 2) { return cpf[0]; }
                cpf.push('.', input.substring(3, 6));
                if (size <= 5) { return cpf.join(''); }
                cpf.push('.', input.substring(6, 9));
                if (size <= 8) { return cpf.join(''); }
                cpf.push('-', input.substring(9));
                return cpf.join('');
            case 1:
                let cnpj = [input.substring(0, 2)];
                if (size <= 1) { return cnpj[0]; }
                cnpj.push('.', input.substring(2, 5));
                if (size <= 5) { return cnpj.join(''); }
                cnpj.push('.', input.substring(5, 8));
                if (size <= 8) { return cnpj.join(''); }
                cnpj.push('/', input.substring(8, 12));
                if (size <= 12) { return cnpj.join(''); }
                cnpj.push('-', input.substring(12));
                return cnpj.join('');
            default:
                return input;
        }
    },

    clear       (fmt, input) {
        const clean = !input ?
            '' :
            input.replace(/[^0-9]/g, '');
        switch (fmt) {
            case 0: return clean.length <= 11 ? clean : clean.substring(0, 11);
            case 1: return clean.length <= 14 ? clean : clean.substring(0, 14);
            default: return clean;
        }
    },

    validate    (fmt, input) {
        const v = input ? this.clear(fmt, input.trim()) : undefined;
        if (!v) { return 0; }
        switch (fmt) {
            case 0:
                if(v.length != 11 || [].every.apply(v, [(c) => c === v[0]])) { return 0 }
                let df = v.substr(0, 9), cf = v.substr(9), tf = 0, cf1, cf2, fi;
                for(fi = 0; fi < 9; ++fi) { tf += (+df.charAt(fi)) * (10 - fi) }
                if(!tf) { return 0 }
                cf1 = 11 - (tf % 11); if(cf1 > 9) { cf1 = 0 }
                if((''+cf1) !== cf.charAt(0)) { return 0 }
                tf = 2 * cf1;
                for(fi = 0; fi < 9; ++fi) { tf += (+df.charAt(fi)) * (11 - fi) }
                cf2 = 11 - (tf % 11); if(cf2 > 9) { cf2 = 0 }
                return (''+cf2) === cf.charAt(1);
            case 1:
                if(v.length != 14) { return 0 }
                let dj = v.substr(0, 12), cj = v.substr(12), tj = 0, cj1, cj2, ji;
                for (ji = 0; ji <  4; ji++) { tj += (+dj.charAt(ji)) * ( 5 - ji) }
                for (ji = 4; ji < 12; ji++) { tj += (+dj.charAt(ji)) * (13 - ji) }
                if(!tj) { return 0 }
                cj1 = 11 - (tj % 11); if (cj1 > 9) { cj1 = 0 }
                if((''+cj1) !== cj.charAt(0)) { return 0 }
                tj = 2 * cj1;
                for (ji = 0; ji <  5; ji++) { tj += (+dj.charAt(ji)) * ( 6 - ji) }
                for (ji = 5; ji < 12; ji++) { tj += (+dj.charAt(ji)) * (14 - ji) }
                cj2 = 11 - (tj % 11); if(cj2 > 9) { cj2 = 0 }
                return (''+cj2) === cj.charAt(1);
            default: return 0;
        }
    }
}