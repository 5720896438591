import '@mdi/font/css/materialdesignicons.css';
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const theme = {
  primary: "#579AF2",
  secondary: "#BCD4DE",
  terciary: "#2d3846",
  accent: "#A0B9BF",
  info: "#2bad70",
  success: "#23CE6B",
  error: "#FE4A49",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})
