// from: https://stackoverflow.com/questions/42199956/how-to-implement-debounce-in-vue2
// author: digout <https://stackoverflow.com/users/841591/digout:
export default function debouncer(fn, ms) {
    let   eventId   = void 0;
    const buff      = new Uint8Array(1);
    buff[0]         = 0;
    return function () {
        eventId && clearTimeout(eventId);
        let args    = [].slice.apply(arguments);
        let self    = this;
        eventId     = setTimeout(async () => {
            if (Atomics.compareExchange(buff, 0, 0, 1) == 0) {
                try {
                    await Promise.resolve(fn.apply(self, args));
                } finally {
                    buff[0] = 0;
                }
            }
        }, ms);
    };
}
