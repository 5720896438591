function isInput (k) {
    return ';8;9;37;38;39;40;'.indexOf(`;${k};`) >= 0;
}
function isDigit (k) {
    return k >= 48 && k <= 57;
}

export default {
    onlyDigits (e) {
        const k = e && e.which || 0;
        return (e.returnValue = k &&
            (!(e.shifKey || e.altKey || e.ctrlKey)) &&
            (isInput(k) || isDigit(k)));
    }
}