<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
import Vue      from "vue";
import client   from "@/services/AdminService";

export default      {
    name            : "App",

    created         () {
        Vue.$globalEvent.$on("httpError", (item) => {
            this.$toast.error(item && item.body && item.body.message || 'Erro não identificado');
        });
        Vue.$globalEvent.$on('httpRequestEnd', async (resp) => {
            if ((resp.status !== 401) || (!sessionStorage.getItem('token'))) { return; }
            this.$toast.error('Sessão expirada!');
            return await new Promise((acc) => setTimeout(acc, 1000))
                .then(() => {
                    client.doLogoff(this.$router);
                });
        });
    },

    data            : () => ({
        error       : "",
        showError   : false,
    }),
};
</script>