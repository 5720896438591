import  Vue             from    "vue";
import  VueRouter       from    "vue-router";

import  AuthRoutes      from    "./views/pages/auth/router";
import  HomeRoutes      from    "./views/pages/home/router";
import  EntityRoutes    from    "./views/pages/entity/router";
import  PlanRoutes      from    "./views/pages/commercialPlan/router";
import  TermsRoutes     from    "./views/pages/termsAndConditions/router";

import  client          from    "./services/AdminService";

Vue.use(VueRouter);
const   routes      = [
    {
        path        : "/auth",
        ...AuthRoutes
    },
    {
        path        : '/home',
        ...HomeRoutes
    },
    {
        path        : '/entity',
        ...EntityRoutes
    },
    {
        path        : '/commercialPlans',
        ...PlanRoutes
    },
    {
        path        : '/termsAndConditions',
        ...TermsRoutes
    },
    {
        path        : "/",
        /* solução para mode: "history", tem que adicionar redirect no Web.config */
        beforeEnter: (to, _, next) => {
          next({
            path    : to.query.path ? decodeURIComponent(to.query.path) : "/auth",
            query   : to.query.path ? {} : to.query,
            hash    : to.hash
          });
        }
    }
];

const   router      = new VueRouter({
    mode            : "history",
    base            : process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const   token   = sessionStorage.getItem("token");
    const   expires = sessionStorage.getItem("expires");
    const   userTxt = sessionStorage.getItem("user");
    const   user    = userTxt && JSON.parse(userTxt) || undefined;

    if (user && !user.acceptTerms) {
        if (from.path !== '/terms-and-conditions' && to.path !== '/terms-and-conditions') {
            next(TermsRoutes.children[0]);
            return;
        }
    }

    if (!(to.meta.requiresAuth)) {
        next();
        return;
    }
    const tstamp    = new Date().toISOString();
    if (!(token && (tstamp < expires))) {
        await client.doLogoff(router);
        return;
    }

    next();
    /*
    if (user.planType === 0 && to.meta.requiresBasic) {
        next();
    } else if (user.planType === 1 && to.meta.requiresPattern) {
        next();
    } else if (user.planType === 2 && to.meta.requiresSenior) {
        next();
    } else if (user.planType === 3 && to.meta.requiresExpert) {
        next();
    } else if (user.planType === 4 && to.meta.requiresProfessional) {
        next();
    } else {
        if (from.path != "/auth") {
            next(from.path);
            //console.log(from.path, to.path)
            EventBus.$emit('abrirModalPlanos', true)
        }
        // alert("Acesso Restrito");
    }
    */
});

export default router;