export default {
    format (input) {
        const size = input && input.length || 0;
        if (size === 0) { return ''; }
        let cep = [input.substring(0, 5)];
        if (size <= 5) { return cep.join(''); }
        cep.push('-', input.substring(5));
        return cep.join('');
    },
    clear  (input) {
        return input && input.replace(/[^0-9]/g, '') || ''
    }
}