export default {
    format (input) {
        const size = input && input.length || 0;
        if (size === 0) { return ''; }
        let cep = ['(', input.substring(0, 2)];
        if (size <= 2) { return cep.join(''); }
        cep.push(')', input.substring(2, 6));
        if (size <= 6) { return cep.join(''); }
        cep.push('-', input.substring(6))
        return cep.join('');
    },
    clear  (input) {
        return input && input.replace(/[^0-9]/g, '') || ''
    }
}