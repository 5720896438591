<template>
    <v-form ref="entplan" v-model="valid" lazy-validation :disabled="loading">
        <v-container class="gp-modal" :style="windowCls">
            <div class="ctrl-panel">
                <button type="button"
                        class="close-modal"
                        @click="sendCloseEditor"><v-icon light>mdi-close-box</v-icon></button>
                <v-item-group>
                    <v-container>
                        <v-item>
                            <ButtonBar :no_save="invalidOrSame"
                                       @save="save"
                                       @cancel="sendCloseEditor" />
                        </v-item>
                        <v-item>
                            <v-card>
                                <v-row class="separator">
                                    <v-col class="title">Entidade</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="entPlan.entityName"
                                                      readonly
                                                      outlined
                                                      dense
                                                      label="Nome"
                                                      placeholder="Nome" />
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="entPlan.entityEmail"
                                                      readonly
                                                      outlined
                                                      dense
                                                      label="e-mail"
                                                      placeholder="e-mail" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-item>
                        <v-item>
                            <v-card>
                                <v-row class="separator">
                                    <v-col class="title">Plano</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select :items="plans"
                                                  item-text="fullname"
                                                  item-value="commercialPlanId"
                                                  label="Plano"
                                                  v-model="entPlan.commercialPlanId"
                                                  required
                                                  outlined
                                                  dense
                                                  clearable
                                                  placeholder="Plano" />
                                    </v-col>
                                    <v-col>
                                        <div class="text-block">{{ planDesc }}</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-item>
                        <v-item>
                            <v-card>
                                <v-row class="separator">
                                    <v-col class="title">Informações adicionais</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea v-model="entPlan.notes"
                                                    placeholder="Anotações"
                                                    label="Anotações"
                                                    outlined
                                                    dense
                                                    lines="3"
                                                    rows="3" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="entPlan.startDate"
                                                      placeholder="Data de início"
                                                      label="Data de início"
                                                      outlined
                                                      dense
                                                      append-icon="mdi-close"
                                                      @click:append="cleanProp('startDate')"
                                                      type="datetime-local" />
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="entPlan.stopDate"
                                                      placeholder="Data de término"
                                                      label="Data de término"
                                                      outlined
                                                      dense
                                                      append-icon="mdi-close"
                                                      @click:append="cleanProp('stopDate')"
                                                      type="datetime-local" />
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="entPlan.expiredDate"
                                                      placeholder="Data de vencimento"
                                                      label="Data de vencimento"
                                                      outlined
                                                      dense
                                                      append-icon="mdi-close"
                                                      @click:append="cleanProp('expiredDate')"
                                                      type="datetime-local" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-item>
                        <v-item>
                            <ButtonBar :no_save="invalidOrSame"
                                       @save="save"
                                       @cancel="sendCloseEditor" />
                        </v-item>
                    </v-container>
                </v-item-group>
            </div>
        </v-container>
    </v-form>
</template>

<style scoped>
.gp-modal {
    position        : fixed;
    width           : 100%;
    height          : 100%;
    min-width       : 100%;
    min-height      : 100%;
    width           : 100%;
    height          : 100%;
    top             : 0;
    left            : 0;
    overflow        : hidden;
    background-color: rgba(0, 0, 0, 0.25);
    z-index         : 1000;
}
.gp-modal .ctrl-panel {
    position        : fixed;
    width           : var(--wWdt);
    min-width       : var(--wWdt);
    max-width       : var(--wWdt);
    height          : var(--wHgt);
    min-height      : var(--wHgt);
    max-height      : var(--wHgt);
    top             : var(--wTop);
    left            : var(--wLft);
    background      : #ffffff;
    box-shadow      : -4px 0px 10px rgba(0, 0, 0, 0.05);
    z-index         : 10;
    overflow-y      : auto;
    border          : 2px outset rgba(128,128,128,0.5);
    border-radius   : 10px;
    padding         : 1em;
    z-index         : 1001;
    font-size       : 0.75em;
}
.gp-modal .ctrl-panel .close-modal {
    width           : 26px;
    height          : 24px;
    position        : fixed;
    top             : var(--bTop);
    left            : var(--bLft);
    z-index         : 1002;
}
.gp-modal .ctrl-panel .v-treeview .row .col {
    cursor: pointer;
}
.gp-modal .ctrl-panel .user-search {
    margin-right: 2em;
}
.gp-modal .ctrl-panel .v-treeview i.disabled {
    color: red;
}
.gp-modal .ctrl-panel .v-card {
    margin: 0.5em;
    padding: 0.5em;
}
.v-text-field input {
    margin: 0 !important;
    padding: 0 !important;
}
.gp-modal .ctrl-panel .row .col div.text-block {
    margin: 1em;
}
</style>

<style>
</style>

<script lang="js">
import EventBus         from '@/views/bus/bus';
import client           from '@/services/AdminService';
import ButtonBar        from './ButtonBar.vue';

export default {
    props               :  {
        instance        : { type: Object },
        spec            : { type: Object }
    },
    data                :  () => ({
        loading         : false,
        valid           : true,
        plans           : [],
        entPlan         : {
            entityId        : 0,
            userId          : '',
            entityName      : '',
            entityEmail     : '',
            commercialPlanId: 0,
            planName        : '',
            planDescription : '',
            purchasedPlanId : 0,
            startDate       : '',
            stopDate        : '',
            expiredDate     : '',
            notes           : ''
        },
        cleanProps      : [
            'userId', 'entityName', 'entityEmail', 'planName', 'planDescription',
            'startDate', 'stopDate', 'expiredDate', 'notes'
        ],
        client
    }),
    components          :  {
        ButtonBar
    },
    methods             :  {
        async loadPlans () {
            if (this.loading) { return; }
            this.loading        = true;
            this.plans.length   = 0;
            try {
                const resp = await this.client.getAdminCommercialPlans();
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.plans.push(...resp.content);
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        async save      () {
            if (this.loading) { return; }
            this.loading = true;
            try {
                this.prepare();
                const resp  = await this.client.saveEntityPlan(this.entPlan);
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.clearEnt();
                setTimeout(() => {
                    this.$toast.success(resp.content || 'Alterações salvas.');
                    this.sendCloseEditor();
                }, 500);
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        prepare         () {
            const e     = this.entPlan;
            const n     = this.cleanProps;
            n.forEach((p) => { if (!e[p]) { e[p] = undefined } });
            if (!this.entPlan.commercialPlanId) { this.entPlan.commercialPlanId = 0; }
        },
        clearEnt        () {
            this.entPlan.entityId           = 0;
            this.entPlan.commercialPlanId   = 0;
            this.cleanProps.forEach((p) => this.entPlan[p] = '');
        },
        sendCloseEditor () {
            EventBus.$emit('closeEntityPlanEditor', true);
        },
        compareText     (a, b) {
            return (a || '') !== (b || '');
        },
        getPlanDesc     (pid) {
            const match = this.plans.filter((p) => p.commercialPlanId === pid);
            return match && match.length && match[0].description || '...';
        },
        cleanProp       (prop) {
            if (prop in this.entPlan) {
                this.entPlan[prop] = undefined;
            }
        }
    },
    watch               :  {},
    computed            :  {
        windowCls       () {
            if (window.innerWidth < 750) {
                return {
                    '--wHgt' : '80%',
                    '--wWdt' : '90%',
                    '--wTop' : '10%',
                    '--wLft' :  '5%',
                    '--bTop' : '11%',
                    '--bLft' : '87%',
                    'display': this.wdisplay
                };
            }
            const hval = this.spec.width  * window.innerHeight;
            const wval = this.spec.height * window.innerWidth;
            const hres = (1 - this.spec.width ) * window.innerHeight / 2;
            const wres = (1 - this.spec.height) * window.innerWidth  / 2;
            const tval = hres + 10;
            const rval = window.innerWidth - wres - 10 - 26;
            return {
                '--wHgt' : `${hval}px`,
                '--wWdt' : `${wval}px`,
                '--wTop' : `${hres}px`,
                '--wLft' : `${wres}px`,
                '--bTop' : `${tval}px`,
                '--bLft' : `${rval}px`,
                'display': this.wdisplay
            };
        },
        invalidOrSame   () {
            const isValid = this.$refs &&
                            this.$refs.entplan &&
                            this.$refs.entplan.validate() ||
                            false;
            const changed = (this.instance.commercialPlanId !== this.entPlan.commercialPlanId) ||
                            this.compareText(this.instance.notes, this.entPlan.notes) ||
                            this.compareText(this.instance.startDate, this.entPlan.startDate) ||
                            this.compareText(this.instance.stopDate, this.entPlan.stopDate) ||
                            this.compareText(this.instance.expiredDate, this.entPlan.expiredDate);
            return !(isValid && changed);
        },
        planDesc        () {
            return this.entPlan.commercialPlanId ?
                this.getPlanDesc(this.entPlan.commercialPlanId) :
                '...';
        }
    },
    mounted             () {
        this.instance && Object.getOwnPropertyNames(this.instance).forEach((prop) => {
            this.entPlan[prop] = this.instance[prop];
        });
        return this.loadPlans();
    }
}
</script>