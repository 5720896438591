<template>
    <v-container class="panelgrid">
        <v-row v-if="loading" class="justify-center align-center">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </v-row>
        <v-row class="listing">
            <v-row class="accordion">
                <v-data-table :headers="headers"
                              :items="items"
                              class="listing-table"
                              :items-per-page="15"
                              dense
                              :loading="loading"
                              loading-text="Carregando..."
                              mobile-breakpoint="750"
                              @click:row="goToDetails">
                    <template v-slot:top>
                        <v-row>
                            <v-col>
                                <span class="title">Planos</span>
                            </v-col>
                            <v-col cols="8"></v-col>
                            <v-col>
                                <v-btn class="btn-tree"
                                       color="primary"
                                       @click="showEntPlans"
                                       title="Ver planos de entidades">
                                    <v-icon light>mdi-office-building-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn class="btn-add" color="primary"
                                       @click="goToNewPlan"
                                       title="Adicionar novo plano">
                                    <v-img class="btn-add-img"
                                           src="@/assets/icons/add_circle_icon.svg"/>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.createdDate="{ item }">
                        <span>{{ formatDate(item.createdDate) }}</span>
                    </template>
                    <template v-slot:item.expiredDate="{ item }">
                        <span>{{ formatDate(item.expiredDate) }}</span>
                    </template>
                </v-data-table>
            </v-row>
            <v-row v-if="!(items && items.length)">
                <v-col cols="12" v-if="!loading">Sem planos cadastrados.</v-col>
                <v-col cols="12" v-if="loading">Carregando...</v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.listing-table .row .v-btn {
    border-radius: 10px;
    background-color: #1296E3;
    color: white;
    min-width: unset;
    min-height: unset;
}
.listing-table .row .btn-add-img {
    max-width: 1rem !important;
    max-height: 1rem !important;
}
.listing-table .row .v-text-field {
    width: 200px !important;
}
.row.listing {
    margin: 0;
}
.listing > .row {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}
.listing .row .col {
    font-size: 0.75em;
    margin: 0.25em;
}
.listing-table {
    min-width: 100%;
    width: 100%;
}
.listing-table table tbody tr td .btn-icon {
    max-width: 1rem !important;
    max-height: 1rem !important;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
}
@media screen and (max-width: 750px) {
}
</style>

<style>
.listing-table .row .title {
    text-align: left !important;
    font-weight: bold !important;
    padding: 0.5rem;
    font-size: 1.25rem;
}
</style>

<script lang="js">
import client   from '../../../services/AdminService';

export default {
    data                : () => ({
        loading         : false,
        headers         : [
            { text: "Nome"     , value: "fullname"              , width:   "20%", sortable: false },
            { text: "Descrição", value: "description"           , width:   "35%", sortable: false },
            { text: "Logins"   , value: "maxNumberLogins"       , width:    "5%", sortable: false },
            { text: "Carteiras", value: "maxNumberWallets"      , width:    "5%", sortable: false },
            { text: "Criação"  , value: "createdDate"           , width: "17.5%", sortable: false },
            { text: "Expira em", value: "expiredDate"           , width: "17.5%", sortable: false }
        ],
        items           : [],
        selected        : undefined,
        client
    }),
    methods             : {
        async loadItems () {
            this.loading        = true;
            this.items.length   = 0;;
            try {
                const resp = await this.client.getAdminCommercialPlans();
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Erro não identificado');
                    return;
                }
                this.items.push(...resp.content);
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading = false;
            }
        },
        formatDate      (v) {
            if (!v) { return '' }
            let xp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(.(\d{1,3}))?$/;
            let mt = `${v}`.match(xp);
            return mt ? `${mt[3]}/${mt[2]}/${mt[1]} ${mt[4]}:${mt[5]}` : v;
        },
        goToNewPlan     () {
            this.$router.push('/commercial-plan-new');
        },
        showEntPlans    () {
            this.$router.push("/entity-plans");
        },
        goToDetails     (item) {
            if (!item) { return; }
            this.$router.push({
                name        : 'commercial-plan-edit',
                params      : {
                    id      : `${item.commercialPlanId}` || 'empty',
                    item,
                    plans   : this.items
                }
            });
        }
    },
    mounted             () {
        const role      = this.$store.getters.$user.systemRoleId;
        if (role !== 1) {
            this.$router.push('/home');
            return;
        }
        this.loadItems();
    }
}
</script>