<template>
    <v-form ref="newent" v-model="valid" lazy-validation :disabled="loading">
        <v-container class="panelgrid">
            <v-row v-if="loading" class="justify-center align-center">
                <v-progress-circular :size="35"
                                    color="primary"
                                    indeterminate />
            </v-row>
            <v-row class="title">
                <v-col cols="6">
                    <span>{{ isUpdate ? "Editar entidade" : "Nova Entidade" }}</span>
                    &nbsp;
                    <span class="hl" v-if="isUpdate && instance.planName">{{ instance.planName }}</span>
                </v-col>
                <v-col cols="6" v-if="instance.entityId > 0">
                    <v-btn @click="toggleStatus(instance.userId)"
                           class="btn-status primary">
                        {{ instance.isActive ? "Desativar" : "Ativar" }}
                    </v-btn>
                </v-col>
                <v-col cols="6" v-else>
                    <span>...</span>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col cols="2">
                    <v-select :items="idTypes"
                              item-text="text"
                              item-value="value"
                              v-model="instance.idType"
                              class="id-type-select"
                              color="secondary"
                              label="Tipo"
                              required
                              outlined
                              dense></v-select>
                </v-col>
                <v-col cols="2">
                    <v-text-field :label="m_idTypeName"
                                  v-model="m_idValue"
                                  :rules="[(v) => !!v || 'CPF/CNPJ obrigatório.', (v) => idFormatter.validate(instance.idType, v) || 'CPF/CNPJ inválido.']"
                                  required
                                  @keypress="keyFilter.onlyDigits"
                                  :maxlength="m_idValueLen"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Nome"
                                v-model="instance.name"
                                :rules="[(v) => !!v || 'Nome obrigatório.']"
                                required
                                maxlength="128"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Nome Comp."
                                v-model="instance.fullname"
                                :rules="[(v) => !!v || 'Nome Comp. obrigatório.']"
                                required
                                maxlength="256"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col>
                    <v-text-field label="e-mail"
                                v-model="instance.email"
                                :rules="[(v) => !!v || 'E-mail obrigatório.', (v) => v && v.indexOf('@') >= 0 || 'E-mail inválido.']"
                                required
                                maxlength="512"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field label="Telefone"
                                v-model="iphone"
                                @keypress="keyFilter.onlyDigits"
                                placeholder="(XX)XXXX-XXXX"
                                hint="Código de área + telefone"
                                maxlength="13"></v-text-field>
                </v-col>
                <v-col>
                    <v-select :items="roles"
                              item-text="fullName"
                              item-value="roleId"
                              v-model="instance.roleId"
                              class="role-id-select"
                              color="secondary"
                              label="Grupo de Permissões"
                              required
                              outlined
                              :disabled="isUpdate"
                              dense></v-select>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col cols="6">
                    <v-text-field label="Endereço"
                                  :autocomplete="getAutoComplete(istreet)"
                                  v-model="istreet"
                                  required
                                  background-color="#DDDDFF"
                                  placeholder="Endereço sem número"
                                  hint="Preencha endereço, cidade e estado para opções de autompletar complemento, bairro e cep"
                                  maxlength="512"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Cidade"
                                  :autocomplete="getAutoComplete(icity)"
                                  v-model="icity"
                                  required
                                  background-color="#DDDDFF"
                                  maxlength="512"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-combobox label="Estado"
                                :items="states"
                                no-data-text="Não encontrado"
                                :autocomplete="getAutoComplete(istate)"
                                v-model="istate"
                                maxlength="2"
                                item-text="uf"
                                item-value="uf"
                                :menu-props="{ bottom: true, offsetY: true }"
                                required
                                placeholder="XX"
                                hint="Sigla do estado"
                                background-color="#DDDDFF"></v-combobox>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col cols="3">
                    <v-text-field label="Número"
                                  :autocomplete="getAutoComplete(instance.addressNumber)"
                                  v-model="instance.addressNumber"
                                  required
                                  maxlength="256"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Complemento"
                                  :autocomplete="getAutoComplete(instance.completionAddress, 1)"
                                  v-model="instance.completionAddress"
                                  maxlength="512"
                                  v-if="!(candidates && candidates.length)"></v-text-field>
                    <v-combobox label="Complemento"
                                :items="filtered"
                                no-data-text=""
                                :autocomplete="getAutoComplete(icompl, 1)"
                                v-model="icompl"
                                maxlength="512"
                                item-text="complemento"
                                item-value="complemento"
                                :menu-props="{ bottom: true, offsetY: true }"
                                v-if="candidates && candidates.length"></v-combobox>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Bairro"
                                  :autocomplete="getAutoComplete(instance.neighborhood)"
                                  v-model="instance.neighborhood"
                                  required
                                  maxlength="512"
                                  v-if="!(candidates && candidates.length)"></v-text-field>
                    <v-combobox label="Bairro"
                                :items="filtered"
                                no-data-text=""
                                :autocomplete="getAutoComplete(ineighborhood)"
                                v-model="ineighborhood"
                                maxlength="512"
                                item-text="bairro"
                                item-value="bairro"
                                :menu-props="{ bottom: true, offsetY: true }"
                                required
                                v-if="candidates && candidates.length"></v-combobox>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="CEP"
                                  :autocomplete="getAutoComplete(izip)"
                                  v-model="izip"
                                  required
                                  maxlength="9"
                                  background-color="#FFDDDD"
                                  placeholder="XXXXX-XXX"
                                  hint="Forneça CEP para preenchimento do restante"
                                  @keypress="keyFilter.onlyDigits"
                                  v-if="!(candidates && candidates.length)"></v-text-field>
                    <v-combobox label="CEP"
                                :items="filtered"
                                no-data-text=""
                                :autocomplete="getAutoComplete(izip)"
                                v-model="izip"
                                maxlength="9"
                                item-text="cep"
                                item-value="cep"
                                :menu-props="{ bottom: true, offsetY: true }"
                                required
                                @keypress="keyFilter.onlyDigits"
                                v-if="candidates && candidates.length"></v-combobox>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col v-if="!isSelf && (!isUpdate || isAdmin)">
                    <v-select :items="sysRoles"
                              item-text="description"
                              item-value="systemRoleId"
                              v-model="iSysRoleId"
                              :rules="[(v) => !!v || 'Papel de sistema é obrigatório.']"
                              class="sys-role-select"
                              color="secondary"
                              label="Papel de sistema"
                              required
                              outlined
                              dense></v-select>
                </v-col>
                <v-col v-else>
                    <v-text-field label="Papel de sistema"
                                  readonly
                                  v-model="eSysRole" />
                </v-col>
                <v-col v-if="!isSelf && (!isUpdate || isAdmin)">
                    <v-autocomplete label="Proprietário"
                                    :items="owners"
                                    item-value="entityId"
                                    item-text="fullname"
                                    :search-input.sync="ownersQuery"
                                    no-data-text="Não encontrada."
                                    v-model="iOwnerId"
                                    maxlength="512"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :rules="[(v) => !isRegular || !!v || 'Proprietário é obrigatório']"
                                    clearable
                                    placeholder="Nome"
                                    hint="Proprietário da entidade."></v-autocomplete>
                </v-col>
                <v-col class="label" v-else>
                    <v-text-field label="Proprietário"
                                  readonly
                                  v-model="instance.ownerEntityName"/>
                </v-col>
                <v-col>
                    <v-text-field label="Data de ativação"
                                  type="datetime-local"
                                  v-model="iactDate"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="fields" cols="12" v-if="!isUpdate">
                <v-col>
                    <v-text-field v-model="instance.password"
                                  placeholder="Senha"
                                  :rules="pwdRules"
                                  color="secondary"
                                  required
                                  outlined
                                  rounded
                                  class="pwd-input"
                                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPwd ? 'text' : 'password'"
                                  @click:append="showPwd = !showPwd" />
                </v-col>
                <v-col>
                    <v-text-field v-model="confirmPwd"
                                  placeholder="Confirmação da senha"
                                  :rules="cnfRules"
                                  color="secondary"
                                  required
                                  outlined
                                  rounded
                                  class="pwd-input"
                                  :append-icon="showCnf ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showCnf ? 'text' : 'password'"
                                  @click:append="showCnf = !showCnf" />
                </v-col>
            </v-row>
            <v-row class="fields" cols="12">
                <v-col class="text-center">
                    <v-btn @click="saveEntity"
                           title="Salvar os dados da entidade"
                           class="btn-ok">Salvar</v-btn>
                </v-col>
                <v-col class="text-center">
                    <v-btn @click="clearEntity"
                           title="Limpar dados preenchidos"
                           class="btn-clean">Limpar</v-btn>
                </v-col>
                <v-col class="text-center">
                    <v-btn @click="goBack"
                           title="Cancelar e voltar para a listagem"
                           class="btn-cancel">Voltar</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<style scoped>
.panelgrid {
    margin: 0;
    padding: 1em;
    overflow-y: auto;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    min-width: 100%;
}
.id-type-select {
    max-width: 130px;
    widows: 130px;
    display: inline-block;
}
.fields .col label span.label {
    margin-right: 1em;
}
.v-text-field input {
    padding-left: 4px;
}
.fields .col button {
    font-weight: bold;
    border-radius: 26px;
}
.fields .col .btn-ok {
    background-color: #33CC33;
    color: white;
}
.fields .col .btn-clean {
    background-color: #33CCCC;
    color: white;
}
.fields .col .btn-cancel {
    background-color: #CC3333;
    color: white;
}
.title .col .btn-status {
    font-weight: bold;
    border-radius: 26px;
    color: white;
}
.fields .col.label {
    align-content: center;
}
.fields .col .label {
    border: 1px solid #CCCCCC;
    border-radius: 27px;
    padding: 1em;
}
.title .hl {
    color: #882266;
}
</style>

<script lang="js">
import  idFormatter     from    '@/services/IdValueFormatter';
import  phoneFormatter  from    '@/services/PhoneFormatter';
import  cepFormatter    from    '@/services/CEPFormatter';
import  dateFormatter   from    '@/services/DateFormatter';
import  client          from    '@/services/AdminService';
import  cepService      from    '@/services/CEPService';
import  debouncer       from    '@/utils/debouncer';
import  keyFilter       from    '@/utils/filter';

export default      {
    data            : () => ({
        loading     : false,
        valid       : true,
        isUpdate    : false,
        isAdmin     : false,
        isSelf      : false,
        instance    : {
            idType              : 0,
            idValue             : undefined,
            fullname            : undefined,
            email               : undefined,
            street              : undefined,
            addressNumber       : undefined,
            completionAddress   : undefined,
            neighborhood        : undefined,
            city                : undefined,
            state               : undefined,
            zipCode             : undefined,
            phoneNumber         : undefined,
            ownerEntityId       : undefined,
            entityRole          : undefined,
            roleId              : undefined,
            createdByEntityId   : undefined,
            createdDate         : undefined,
            activatedDate       : undefined,
            cancelledDate       : undefined
        },
        istreet     : undefined,
        icity       : undefined,
        istate      : undefined,
        idTypes     : [
            {
                value: 0,
                text : 'CPF'
            },
            {
                value: 1,
                text: 'CNPJ'
            }
        ],
        states      : [
            { uf: "AC", name: "Acre" },
            { uf: "AL", name: "Alagoas" },
            { uf: "AM", name: "Amazonas" },
            { uf: "AP", name: "Amapá" },
            { uf: "BA", name: "Bahia" },
            { uf: "CE", name: "Ceará" },
            { uf: "DF", name: "Distrito Federal" },
            { uf: "ES", name: "Espírito Santo" },
            { uf: "GO", name: "Goiás" },
            { uf: "MA", name: "Maranhão" },
            { uf: "MG", name: "Minas Gerais" },
            { uf: "MS", name: "Mato Grosso do Sul" },
            { uf: "MT", name: "Mato Grosso" },
            { uf: "PA", name: "Pará" },
            { uf: "PB", name: "Paraíba" },
            { uf: "PE", name: "Pernambuco" },
            { uf: "PI", name: "Piauí" },
            { uf: "PR", name: "Paraná" },
            { uf: "RJ", name: "Rio de Janeiro" },
            { uf: "RN", name: "Rio Grande do Norte" },
            { uf: "RO", name: "Rondônia" },
            { uf: "RR", name: "Roraima" },
            { uf: "RS", name: "Rio Grande do Sul" },
            { uf: "SC", name: "Santa Catarina" },
            { uf: "SE", name: "Sergipe" },
            { uf: "SP", name: "São Paulo" },
            { uf: "TO", name: "Tocantins" }
        ],
        store       : {
            sRoleId : 0,
            roleId  : 0,
            sysRoles: [],
            roles   : []
        },
        sysRoles    : [],
        roles       : [],
        entities    : [],
        client,
        cepService,
        idFormatter,
        phoneFormatter,
        cepFormatter,
        dateFormatter,
        keyFilter,
        candidates  : [],
        filtered    : [],
        owners      : [],
        ownersQuery : undefined,
        lastQuery   : undefined,
        confirmPwd  : undefined,
        pwdRules    : [(v) => !!v || "Requerida!"],
        cnfRules    : [(v) => !!v || "Requerida!"],
        showPwd     : false,
        showCnf     : false,
        isNewOrAdmin: false
    }),
    methods         : {
        updateOwnerRoles    () {
            this.sysRoles.length    = 0;
            this.roles.length       = 0;
            const user              = this.$store.getters.$user;
            const oid               = this.instance.ownerEntityId;
            const {
                sysRoles,
                roles
            }                       = this.store;
            const comparer          = this.instance.entityId &&
                                      this.instance.userId === user.id ?
                                      ((l, r) => l >= r) :
                                      ((l, r) => l > r);
            const owner             = this.entities.filter((e) => e.entityId === oid)[0];
            if (!owner)             {
                const eRole         = user.systemRoleId;
                if (eRole === 1) {
                    this.sysRoles.push(...sysRoles);
                    this.roles.push(...roles);
                    return;
                }
                this.sysRoles.push(...sysRoles.filter((s) => comparer(s.systemRoleId, eRole)));
                this.roles.push(...roles.filter((r) => comparer(r.roleId, user.roleId)));
                return;
            }
            this.sysRoles.push(...sysRoles.filter((s) => comparer(s.systemRoleId, owner.entityRole)));
            this.roles.push(...roles.filter((r) => comparer(r.roleId, owner.roleId)));
        },
        updateRoles         (pSysRoles, pRoles) {
            this.sysRoles.length    = 0;
            this.roles.length       = 0;
            const {
                sRoleId,
                roleId,
                sysRoles,
                roles
            }                       = this.store;
            sysRoles.length         = 0;
            sysRoles.push(...pSysRoles);
            roles.length            = 0;
            roles.push(...pRoles);

            if (this.instance.ownerEntityId) {
                this.updateOwnerRoles();
                return;
            }
            const comparer          = this.instance.entityId &&
                                      this.instance.userId === this.$store.getters.$user.id ?
                                      ((l, r) => l >= r) :
                                      ((l, r) => l > r);
            const eRole             = this.instance.entityRole;
            if (eRole) {
                this.sysRoles.push(...sysRoles.filter((s) => comparer(s.systemRoleId, eRole)));
                this.roles.push(...roles.filter((r) => r.roleId > this.instance.roleId));
                return;
            }
            if (!sRoleId)           { return; }
            if (sRoleId === 1) {
                this.sysRoles.push(...sysRoles);
                this.roles.push(...roles);
                return;
            }
            this.sysRoles.push(...sysRoles.filter((s) => comparer(s.systemRoleId, sRoleId)));
            this.roles.push(...roles.filter((r) => r.roleId > roleId));
        },
        getRolesBundle      (user) {
            if (this.loading)   { return; }
            this.store.sysRoles.length  = 0;
            this.store.roles.length     = 0;
            this.sysRoles.length        = 0;
            this.roles.length           = 0;
            this.store.roleId           = user && user.roleId       || 0;
            this.store.sRoleId          = user && user.systemRoleId || 0;
            const sysroleId             = this.store.sRoleId;
            if (!sysroleId)             { return; }
            this.loading                = true;
            return this.client.getRolesBundle()
                .then((resp) => {
                    this.loading    = false;
                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }
                    const {
                        roles,
                        sysRoles
                    }               =   resp.content;
                    this.updateRoles(sysRoles, roles);
                })
        },
        goBack              () {
            this.$router.push('/home');
        },
        clearEntity         () {
            this.instance.idType            = 0;
            this.instance.idValue           = undefined;
            this.instance.fullname          = undefined;
            this.instance.email             = undefined;
            this.instance.street            = undefined;
            this.instance.addressNumber     = undefined;
            this.instance.completionAddress = undefined;
            this.instance.neighborhood      = undefined;
            this.instance.city              = undefined;
            this.instance.state             = undefined;
            this.instance.zipCode           = undefined;
            this.instance.phoneNumber       = undefined;
            this.instance.ownerEntityId     = undefined;
            this.instance.entityRole        = undefined;
            this.instance.roleId            = undefined;
            this.instance.createdByEntityId = undefined;
            this.instance.createdDate       = undefined;
            this.instance.activatedDate     = undefined;
            this.instance.cancelledDate     = undefined;
            this.candidates.length          = 0;
            this.filtered.length            = 0;
            this.owners.length              = 0;
        },
        saveEntity          () {
            if (this.loading) { return; }
            const isValid = this.$refs.newent.validate() && (this.instance.password === this.confirmPwd);
            if (!isValid) {
                return;
            }
            this.loading = true;
            return this.client.storeAdminEntity(this.instance, this.isUpdate)
                .then((resp) => {
                    this.loading = false;
                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }

                    this.$toast.info('Entidade armazenada com sucesso.');
                    this.clearEntity();
                    this.goBack();
                });
        },
        autoFillByCep       () {
            if (this.instance.street && this.instance.city &&
                this.instance.state && this.instance.neighborhood) {
                return;
            }
            if (this.candidates && this.candidates.length) {
                return;
            }
            if (!this.instance.zipCode || (this.instance.zipCode.length !== 8)) {
                return;
            }
            if (this.loading) { return; }
            this.candidates.length  = 0;
            this.filtered.length    = 0;
            try {
                this.loading = true;
                return this.cepService.searchZip(this.instance.zipCode)
                    .then((resp) => {
                        if (!resp.success) {
                            this.$toast.error(resp.message);
                            return;
                        }

                        const json = resp.content;
                        if (json.erro) {
                            this.$toast.error('CEP não encontrado.');
                        } else {
                            this.istreet                    = json.logradouro;
                            this.icity                      = json.localidade;
                            this.istate                     = (json.uf || '').toUpperCase();
                            this.instance.neighborhood      = json.bairro;
                            this.instance.completionAddress = json.complemento;
                        }
                    });
            } finally {
                this.loading = false;
            }
        },
        autoFillByAddress   () {
            if (this.instance.zipCode && this.instance.completionAddress) {
                return;
            }
            if ((!this.istate) || (this.istate.length < 2) || (!this.icity) || (!this.istreet)) {
                return;
            }

            if (this.loading) { return; }
            this.candidates.length  = 0;
            this.filtered.length    = 0;
            try {
                this.loading = true;
                return this.cepService.searchAddress(this.istate, this.icity, this.istreet)
                    .then((resp) => {
                        if (!resp.success) {
                            this.$toast.error(resp.message);
                            return;
                        }

                        const json = resp.content;
                        this.candidates.push(...json);
                        this.filtered.push(...json);
                    });
            } finally {
                this.loading = false;
            }
        },
        doFillByCep         : async function () { return Promise.resolve(); },
        doFillByAddress     : async function () { return Promise.resolve(); },
        zipChanged          (input) {
            if (!(this.candidates && this.candidates.length)) { return; }
            this.filtered = this.candidates
                .filter((c) => input === this.cepFormatter.clear(c.cep));
            if (this.filtered.length !== 1) { return ; }
            this.instance.zipCode           = input;
            this.instance.neighborhood      = this.filtered[0].bairro;
            this.instance.completionAddress = this.filtered[0].complemento;
        },
        neighChanged        (input) {
            if (!(this.candidates && this.candidates.length)) { return; }
            this.filtered = this.candidates
                .filter((c) => input.localeCompare(c.bairro) === 0);
            if (this.filtered.length !== 1) { return; }
            this.instance.zipCode           = this.filtered[0].cep;
            this.instance.neighborhood      = input;
            this.instance.completionAddress = this.filtered[0].complemento;
        },
        complChanged        (input) {
            if (!(this.candidates && this.candidates.length)) { return; }
            this.filtered = this.candidates
                .filter((c) => input.localeCompare(c.complemento) === 0);
            if (this.filtered.length !== 1) { return; }
            this.instance.zipCode           = this.filtered[0].cep;
            this.instance.neighborhood      = this.filtered[0].bairro;
            this.instance.completionAddress = input;
        },
        getOwners           (query) {
            const input     = query && query.trim() || '';
            this.owners.length  = 0;
            if (!(input && input.length > 3 && input !== this.lastQuery)) {
                this.owners.push(...this.entities);
                return;
            }
            this.owners.push(...this.entities.filter((e) =>
                e.fullname  &&
                e.fullname.indexOf(input) >= 0));
        },
        doOwners            : function () { return Promise.resolve([]); },
        getAutoComplete     (v, i) {
            return !v ? 'on' : 'off';
        },
        async loadData      () {
            this.owners.length      = 0;
            this.entities.length    = 0;
            const hasParams         = !!this.$route.params.item &&
                                      !!this.$route.params.entities;
            if (hasParams) {
                const       {
                    item,
                    entities
                }                   = this.$route.params;
                const user          = this.$store.getters.$user;
                const self          = user && item.userId === user.id;
                this.isSelf         = !user || item.userId === user.id;
                this.isNewOrAdmin   = !self && (!this.isUpdate || this.isAdmin);
                this.owners.push(...entities);
                this.entities.push(...entities);
                Object.getOwnPropertyNames(item).forEach((prop) =>
                    this.instance[prop] = item[prop]);
                this.updateOwnerRoles();
            } else {
                const parts         = window.location.pathname.split('/');
                const id            = this.isUpdate ? +(parts[parts.length - 1]) : 0;
                this.loading        = true;
                try {
                    const resp      = await this.client.getAdminEntities(1, 10, true);
                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }
                    const entities  = resp.content;
                    this.owners.push(...entities);
                    this.entities.push(...entities);
                    if (this.isUpdate) {
                        const target    = entities.filter((e) => e.entityId === id)[0];
                        if (!target) {
                            this.$toast.error(`Entidade com id ${id} não encontrada`);
                            setTimeout(() => this.goBack(), 2000);
                            return;
                        }
                        Object.getOwnPropertyNames(target).forEach((prop) => this.instance[prop] = target[prop]);
                    }
                    this.updateOwnerRoles();
                } catch (e) {
                    this.$toast.error(e.message || 'Erro não identificado');
                } finally {
                    this.loading    = false;
                }
            }
        },
        async toggleStatus        (userId) {
            if (!userId) { return; }

            this.loading        = true;
            try {
                var resp        = await this.client.toggleActiveStatus(userId);
                if (!resp.success) {
                    this.$toast.error(resp.message || 'Falha não identificada.');
                    return;
                }
                const data      = resp.content;
                this.$toast.info(data && data.message || 'Alteração efetuada.');
                setTimeout(() => this.goBack(), 500);
            } catch (e) {
                this.$toast.error(e.message || 'Erro não identificado');
            } finally {
                this.loading    = false;
            }
        }
    },
    watch           : {
        izip        : function (v) {
            this.instance.zipCode = v.replace(/[^0-9]/g, '');
            this.doFillByCep();
        },
        istreet     : function (v) {
            this.instance.street = v;
            this.doFillByAddress();
        },
        icity       : function (v) {
            this.instance.city = v;
            this.doFillByAddress();
        },
        istate      : function (v) {
            this.instance.state = v;
            this.doFillByAddress();
        },
        ownersQuery : function (q) {
            this.doOwners(q);
        }
    },
    computed        : {
        isRegular   : {
            get     : function () {
                return this.$store.getters.$user.systemRoleId > 1;
            }
        },
        m_idTypeName: {
            get     : function () {
                switch (this.instance.idType) {
                    case 0: return 'CPF';
                    case 1: return 'CNPJ';
                    default: return 'Escolha';
                }
            }
        },
        m_idValue   : {
            get     : function ()  {
                return this.idFormatter.format(
                    this.instance.idType,
                    this.instance.idValue);
            },
            set     : function (v) {
                this.instance.idValue = this.idFormatter.clear(this.instance.idType, v);
            }
        },
        m_idValueLen: {
            get     : function () {
                switch (this.instance.idType) {
                    case 0: return 11 + 3;
                    case 1: return 14 + 4;
                    default: return 1024;
                }
            }
        },
        iphone      : {
            get     : function () {
                return this.phoneFormatter.format(this.instance.phoneNumber);
            },
            set     : function (v) {
                this.instance.phoneNumber = this.phoneFormatter.clear(v);
            }
        },
        izip        : {
            get     : function () {
                return this.cepFormatter.format(this.instance.zipCode)
            },
            set     : function (v) {
                const input = this.cepFormatter.clear(v ? v.cep || v || '' : '');
                this.instance.zipCode = input;
                this.zipChanged(input);
            }
        },
        ineighborhood : {
            get     : function () {
                return this.instance.neighborhood;
            },
            set     : function (v) {
                const input = (v && v.bairro || '').trim();
                this.instance.neighborhood = input;
                this.neighChanged(input);
            }
        },
        icompl      : {
            get     : function () {
                return this.instance.completionAddress;
            },
            set     : function (v) {
                const input = (v && v.complemento || '').trim();
                this.instance.completionAddress = input;
                this.complChanged(input);
            }
        },
        iactDate    : {
            get     : function () {
                return this.instance.activatedDate
            },
            set     : function (v) {
                this.instance.activatedDate = this.dateFormatter.prepare(v);
            }
        },
        iOwnerId    : {
            get     : function () {
                return this.instance.ownerEntityId;
            },
            set     : function (v) {
                this.instance.ownerEntityId = v;
                this.updateOwnerRoles();
            }
        },
        iSysRoleId  : {
            get     : function () {
                return this.instance.entityRole;
            },
            set     : function (v) {
                this.instance.entityRole = v;
                if (v <= 0) { return; }
                this.owners.length = 0;
                this.owners.push(...this.entities.filter((e) => e.entityRole < v));
            }
        },
        eSysRole    : {
            get     : function () {
                const target = this.sysRoles
                    .filter((r) => r.systemRoleId === this.instance.entityRole)[0];
                return target && target.description || 'N/A';
            }
        }
    },
    mounted         () {
        this.doFillByCep        = debouncer(this.autoFillByCep,     1000);
        this.doFillByAddress    = debouncer(this.autoFillByAddress, 1000);
        this.doOwners           = debouncer(this.getOwners,         1000);
        const user              = this.$store.getters.$user;
        this.isUpdate           = window.location.pathname.indexOf('entity-edit') >= 0;
        this.isAdmin            = user && user.systemRoleId === 1;
        if (!user) {
            this.goBack();
            return;
        }
        this.getRolesBundle(user).then(() => this.loadData());
        this.cnfRules.push((_) => this.instance.password === this.confirmPwd || 'Valores diferentes!' );
    }
}
</script>