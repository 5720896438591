import  Layout  from    "../../internal/Layout.vue";
import  Home    from    "./Home.vue";

const HomeRoutes                = {
    component                   : Layout,
    children                    : [
        {
            name                : "home",
            path                : "/",
            component           : Home,
            meta                : {
                requiresAuth    : true,
                requiresAdmin   : true,
            },
        }
    ],
};

export default HomeRoutes;
