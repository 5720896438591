<template>
  <v-container class="background">
    <router-view />
  </v-container>
</template>

<style scoped>
.background {
    max-width: none;
    background: #f6f6f6;
    padding: 1em !important;
    height: 100%;
    overflow: hidden;
}
</style>

<script lang="js">
export default {
    name: "InternalLayout",
};
</script>