import  Layout          from    '../../internal/Layout.vue';
import  EntityNew       from    './EntityNew.vue';

const   EntityRoutes    = {
    component           : Layout,
    children            : [
        {
            name        : 'entity-new',
            path        : '/entity-new',
            component   : EntityNew,
            meta        : {}
        },
        {
            name        : 'entity-edit',
            path        : '/entity-edit/:id',
            component   : EntityNew,
            props       : true,
            meta        : {}
        }
    ]
};

export default EntityRoutes;