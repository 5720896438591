<template>
    <v-card class="actions">
        <v-row>
            <v-col class="align-center">
                <v-btn color="primary" title="Salvar as alterações"
                       :disabled="no_save"
                       @click="onSaveAction">Salvar</v-btn>
            </v-col>
            <v-col class="align-center">
                <v-btn color="accent" title="Cancelar e voltar para a listagem"
                       @click="onCancelAction">Voltar</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped>
.actions .align-center {
    text-align: center;
}
.actions .align-center .v-btn {
    border-radius: 20px;
}
.actions .align-center .v-btn {
    min-width: 200px !important;
    max-width: 200px !important;
}
</style>

<script lang="js">
export default {
    props               : {
        'no_save'       : { type: Boolean }
    },
    methods             : {
        onSaveAction    () {
            this.$emit('save', true);
        },
        onCancelAction  () {
            this.$emit('cancel', true);
        }
    }
}
</script>